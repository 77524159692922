<template>
  <v-row no-gutters>
    <v-col
      :style="`background-image: url(${require('@/assets/back_flynth_small.png')})`"
      cols="12"
      md="6"
      class="leftPanel align-center"
      :class="{ 'full-page': $vuetify.breakpoint.mdAndUp }"
    >
      <ta-left-intro />
    </v-col>
    <v-col cols="12" md="6" class="justify-center align-center">
      <v-row class="ml-15 mt-15 mr-15">
        In uw email uitnodiging treft u een familiecode aan. Met deze code
        krijgt u toegang. <br />
        Controleer eventueel uw spambox.
      </v-row>

      <v-row class="ml-15 mt-15 mr-15">
        <v-text-field
          outlined
          shaped
          placeholder="Familiecode"
          v-model="familyCode"
          required
        />
      </v-row>
      <v-row class="ml-15 mt-15 mr-15">
        <flash-message :error="error" v-if="error" key="error" />
      </v-row>
      <div class="text-end mt-5 mr-15 mb-15">
        <v-btn elevation="15" class="primary" v-if="errorBtn" @click="login"
          >nogmaals</v-btn
        >
        <v-btn elevation="15" class="primary" v-if="!errorBtn" @click="login"
          >Verder</v-btn
        >
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { getError } from "@/utils/helpers";
import { mapGetters } from "vuex";
import FlashMessage from "@/components/FlashMessage";

export default {
  name: "LoginFam",
  components: { FlashMessage },
  data() {
    return {
      error: null,
      familyCode: null,
      valid: false,
      errorBtn: false,
    };
  },
  methods: {
    startOver() {
      this.$router.push({ name: "LoginFam" });
    },
    toggle_dark_mode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    async login() {
      let data = {
        token: this.familyCode,
      };
      this.error = null;
      try {
        await this.$store.dispatch("auth/loginFam", data);
        if (this.stepFamily) {
          this.$router.push({ name: "Intro" });
        } else {
          console.log("error");
          this.errorBtn = true;
          const error = Error(
            "Deze familiecode is onjuist of verlopen, probeer het a.u.b opnieuw. Mocht u problemen blijven ondervinden dan verzoeken we contact met ons op te nemen."
          );
          error.name = "Fetch User";
          throw error;
        }
      } catch (error) {
        this.error = getError(error);
      }
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["stepFamily"]),
  },
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal !important; /* maybe !important  */
}

.height-col {
  height: 100vh;
}
</style>
