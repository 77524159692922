<template>
  <div class="mt-5 mb-5">
    <h4 class="textColor--text">
      {{ statText.statement }}
      <v-icon
        v-if="statText.comment"
        color="secondary"
        dark
        @click.stop="showNote = true"
        >mdi-chat</v-icon
      >
    </h4>
    <fc-note-modal
      :note="statText.note"
      :visible="showNote"
      @close="showNote = false"
    />
  </div>
</template>

<script>
export default {
  name: "FcStatement",
  props: ["statText"],
  data() {
    return {
      showNote: false,
    };
  },
};
</script>

<style scoped></style>
