<template>
  <div>
    <h3 class="accent--text">{{ textIn[step].header }}</h3>
    <br /><br />
    <div class="textColorLeft--text">
      {{ textIn[step].text_a }}
    </div>
    <br />
    <div class="textColorLeft--text">
      {{ textIn[step].text_b }}
    </div>
    <br />
    <div class="textColorLeft--text">
      {{ textIn[step].text_c }}
    </div>
    <br />
    <div class="textColorLeft--text">
      {{ textIn[step].text_d }}
    </div>
    <br />
    <div class="textColorLeft--text">
      {{ textIn[step].text_e }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FcTextLeft",
  props: ["textIn", "step"],
};
</script>

<style scoped></style>
